import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyC_ebG-xDxz7o_OPZOqj4CG1xGvpzkAbkY",
  authDomain: "mismatch-87625.firebaseapp.com",
  databaseURL: "https://mismatch-87625.firebaseio.com",
  projectId: "mismatch",
  storageBucket: "mismatch.appspot.com",
  messagingSenderId: "575261809706",
  appId: "1:575261809706:web:49273ec995bb17f00d3753",
  measurementId: "G-7G90HZPY0Q"
});

/* HACK, todo fix this: This function gets the thumbnail version of an image file */
async function toFbThumbUrl(url: string) {
  const userid = "alovelace"; //hardcode for the purposes of this hack
  const path = url.split('/');
  let ref: firebase.storage.Reference | null = null;
  if(url.includes("my-closet")) {
    ref = storage.ref(`users/${userid}/closet/thumbs/${path[path.length - 1]}_400x500`)
  }
  if(url.includes("fashion-pics")) {
    const fileName = path[path.length - 1].replace(".jpg", "_400x500.jpg")
    ref = storage.ref(`users/${userid}/fashion-pics/${path[path.length - 2]}/thumbs/${fileName}`);
  }
  if(!ref)  return null;
  try {
    let downloadUrl = await ref.getDownloadURL();
    return downloadUrl;
  }
  catch(err) {
    console.log("Error: ", err);
    return null;
  }
}

const db = firebaseApp.firestore();
const storage = firebaseApp.storage();

export { db, storage, toFbThumbUrl };
